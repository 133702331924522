:root {
    --darkerBlue:#8adbdb;
    --lightBlue: #BCFEFF;
  }

.form-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 3%;
    margin-bottom: 2%;
    padding: 10%;
    background-color: black;
    color: var(--darkerBlue);
    border-radius: 25px;
    width: 60%;
    font-family: Genos;
    font-size: 1.5rem;
    font-weight: bold;
    height: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
    margin-right: 15%;
}

.form-full {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 25.7%;
    width: 100%;
}

.form-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 3%;
    width: 100%;
    padding: 5%;
}

.form-button {
    color:black;
    background-color: var(--darkerBlue);
    border-radius: 5px;
    border: 1px solid var(--darkerBlue);
    font-weight: bold;
}

.form-button:hover {
    background-color: black;
    color: var(--lightBlue);
    border: 1px solid var(--darkerBlue);
}

input, textarea {
    border-radius: 5px;
    border: 1px solid var(--darkerBlue);
    background-color: var(--darkerBlue);
    color: black;
    width: 120%;
    font-weight: bold;
}
.message {
    height: 150%;
}

label {
    font-weight: normal;
    margin-bottom: 1%;
}

.email-reference {
    font-weight: normal;
    margin-top: 4%;
    text-align: center;
    width: 90%;
}

@media (max-width: 500px) {
    .email-reference {
        font-size: medium;
    }
}

/* @media (max-width: 600px) {
    .form-area {
        margin-left: 1%;
        margin-right: 1%;
        width: 80%;
    }
} */

@media (max-width: 600px) {
    .form-area {
        width: 95%;
        margin-bottom: 25%;
        margin-right: 1%;
        margin-left: 1%;
    }
}

@media (max-width: 1500px) {
    .form-full {
        margin-top: 35%;
    }
    .form-area {
        margin-left: 1%;
        margin-right: 1%;
        width: 80%;
    }
}

@media (max-width: 1025px) {
    .form-area {
        width: 60%;
        margin-bottom: 15%;
    }
}