body {
  background-image: url("../Images/background.jpg");
  background-attachment: fixed;
}

.app-section {
  position:absolute;
  height: 100%;
  width: 100%;
}

.static-panel {
  margin-left: 25%;
  margin-right: 15%;
} 


/*Adjustments for small screen displays*/
@media (max-width: 500px) {
  .app-section {
      margin-top: 25%;
  }

  }

@media (max-width: 1700px) {
  .static-panel {
      margin-left: 25%;
      margin-right: 10%;
  }
}
  

@media (max-width: 1025px) {
  .static-panel {
      margin-left: 1%;
      margin-right: 1%;
  }
}

@media (max-width: 500px) {
  .about-text-content  {
    margin-bottom: 50% !important;
  }
  }



