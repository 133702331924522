:root {
    --lightBlue: #BCFEFF;
  }

.short-presentation {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    padding: 0 1rem;
    font-family: Genos;
    font-size: 2rem;
    font-weight: 600;
    margin-top: 40%;
    margin-left: 2%;
    text-align: justify; 
}

.about-text-content {
    display: flex;
    margin-top: 3%;
    color: var(--lightBlue);
    /* color: rgb(173, 240, 241); */
    /* color: rgb(188, 254, 255); */
    /* color: rgb(204, 254, 251); */
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    text-align: justify;
    margin-bottom: 10%;
}

h2 {
    display: flex;
    margin-top: 3%;
}

@media (max-width: 500px) {
    .about-text-content  {
      margin-bottom: 50%;
    }
}

@media (max-height: 600px) {
    .about-text-content  {
        margin-bottom: 15%;
    }
}

@media (max-width: 1500px) {
    .short-presentation  {
        margin-top: 40%;
    }
}

@media (max-width: 1300px) {
    .short-presentation  {
        margin-top: 45%;
    }
}

@media (max-width: 1200px) {
    .short-presentation  {
        margin-top: 50%;
    }
}