:root {
    --lightBlue: #BCFEFF;
  }

.team-projects-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items:flex-start;
    margin-bottom: 10%;
}

.ctrl-freaks, 
.codetta-stone {
    display: flex;
    flex-direction: column;
    align-items: center;  
}

.team-projects-title {
    text-align: center;
    color: var(--lightBlue);
    text-shadow: -1px 0 rgb(0, 0, 0), 0 1px rgb(0, 0, 0), 1px 0 rgb(0, 0, 0), 0 -1px rgb(0, 0, 0);
    margin-bottom: 4%;
}


@media (max-width: 900px) {
    .team-projects-container  {
        margin-bottom: 20%;
    }
}

@media (max-width: 1550px) {
    .project-title {
        font-size: 2rem;
    }
    .project-description {
        font-size: 1.5rem;
    }
}

@media (max-width: 1250px) {
    .project-title {
        font-size: 1.7rem;
    }
    .project-description {
        font-size: 1.1rem;
    }
}

@media (max-width: 1025px) {
    .project-title {
        font-size: 2rem;
    }
    .project-description {
        font-size: 1.5rem;
    }
}

@media (max-width: 950px) {
    .dictionary-img,
    .task-manager-image-container:hover .task-dark 
    {
        width: 85%;
    }
    .project-links {
        margin-left: 6%;
    }
    .projects-links-title {
        font-size: 1.6rem;
    }
    .app-link {
        font-size: 1.5rem;
    }
}

@media (max-width: 750px) {
    .project-title {
        font-size: 1.7rem;
    }
    .project-description {
        font-size: 1.2rem;
    }
    .projects-links-title {
        font-size: 1.4rem;
    }
    .app-link {
        font-size: 1.3rem;
    }
}

@media (max-width: 560px) {
    .project-title {
        font-size: 1.6rem;
    }
    .project-description {
        width: 85%;
    }
    .projects-links-title {
        font-size: 1.3rem;
    }
    .app-link {
        font-size: 1.1rem;
    }
    .dictionary-app {
        margin-bottom: 10%;
    }
}

@media (max-width: 450px) {
    .project-title {
        font-size: 1.4rem;
    }
}


