.footer {
  position: fixed;
  background-color: black;
  color: #8adbdb;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px;
  text-align: center;
  border: 1px solid #364d4d;
  height: 5%;
}

/*The sidebar element will ot be displayed on small screens*/
@media (min-width: 1026px) {
    .footer {
      display: none;
    }
    }

@media (max-height: 930px) {
  .footer {
    height: 7%;
  }
}

@media (max-height: 650px) {
  .footer {
    height: 8%;
  }
}

@media (max-height: 530px) {
  .footer {
    height: 12%;
  }
}

@media (max-height: 350px) {
  .footer {
    height: 20%;
  }
}
