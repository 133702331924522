:root {
    --darkerBlue:#8adbdb;
    --lightBlue: #BCFEFF;
  }

.content-flex {
    display: flex;
}

.main-cv-area {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
/* CV intro */
.cv-intro {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top:3%;
    margin-bottom: 3%;
}

/*All content besides intro*/
.cv-content {
    flex-direction: column;
    background-color: black;
    padding-left: 10%;
    padding-right: 10%;
}

.cv-skills {
    flex-direction: column;
}
.skills-list {
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.cv-profile-all {
    background-color: black;
    color: var(--darkerBlue);
    display: flex;
    flex-direction: column;
    margin-bottom: 2%;
    border-radius: 25px;
    font-family: Genos;
    font-size: 1.5rem;
}
.cv-content {
    border-radius: 25px;
    margin-bottom: 10%;
}
.cv-profile {
    background-color: black;
    color: var(--darkerBlue);
    display: flex;
    flex-direction: column; 
}

.empty-area {
    display: flex;
    margin-top: 29%;
}

h2,h3 {
    display: block;
    color: var(--darkerBlue);
    margin-bottom: 20px;
    text-align: center;
}

/* Add top and bottom borders to section titles */
.section-title {
    border-top: 1px solid var(--darkerBlue);
    border-bottom: 1px solid var(--darkerBlue);
    padding: 10px 0;
    margin-bottom: 20px;
    text-align: center;
}


@media (max-width: 1025px) {
    .cv-profile-all {
        margin-bottom: 10%;
    }
    }

@media (max-width: 550px) {
    .cv-content {
      margin-bottom: 30%;
    }
    .cv-profile-all {
        margin-bottom: 20%;
    }
    }

.download-cv-button {
    background-color: black;
    border-radius: 5px;
    border: 1px solid var(--darkerBlue);
    font-weight: bold;
    margin-bottom: 1%;
    margin-right: 2%;
}

.empty-area {
    display: flex;
    margin-top: 29%;
    width: 100%;
    justify-content: flex-end;
}

.download-cv-link {
    color: var(--darkerBlue);
    text-decoration: none;
}

.download-cv-button:hover > .download-cv-link {
    background-color: var(--darkerBlue);
    color: black;
    text-decoration: none;
}

.download-cv-button:hover {
    background-color: var(--darkerBlue);
    border: 1px solid black;
}
