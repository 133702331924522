:root {
    --lightBlue: #BCFEFF;
}

.personal-projects-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items:flex-start;
    margin-bottom: 10%;
}

.task-manager, 
.personal-website,
.dictionary-app {
    display: flex;
    flex-direction: column;
    align-items: center;  
}

.personal-projects-title, .project-title {
    text-align: center;
    color: var(--lightBlue);
    text-shadow: -1px 0 rgb(0, 0, 0), 0 1px rgb(0, 0, 0), 1px 0 rgb(0, 0, 0), 0 -1px rgb(0, 0, 0);
    margin-bottom: 4%;
}

.project-title {
    margin-top: 5%;
    margin-bottom:2%;
}

.project-description{
    text-align: justify;
    color: var(--lightBlue);
    margin-bottom: 4%;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.main-projects-section {
    margin-top: 25%;
    margin-bottom: 5%;
    display: flex;
    flex-direction: column;
    font-family: Genos;
}

@media (max-width: 2500px) {
    .main-projects-section {
        margin-top: 20%;
    }
}

.task-manager-image-container,
.personal-website-image-container,
.dictionary-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.dictionary-img {
    max-width: 100%;
    width: 70%;
    height: auto;
    border: 1px solid var(--lightBlue);
    border-radius: 15px;
}

@media (max-width: 900px) {
    .personal-projects-container  {
        margin-bottom: 20%;
    }
}

.task-dark {
    display: none;
}

.task-manager-image-container {
    position: relative;
}

.task-manager-image-container:hover .task-dark {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    width: 70%;
    height: 100%;
}

.project-links {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    margin-left: 15%;
}

.projects-links-title {
    font-size: 1.8rem;
    padding: 0 1rem;
    color: var(--lightBlue);
    text-shadow: -1px 0 rgb(0, 0, 0), 0 1px rgb(0, 0, 0), 1px 0 rgb(0, 0, 0), 0 -1px rgb(0, 0, 0);
    margin-top: 5%;
    margin-bottom: 0;
}

.app-link {
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    font-size: 1.7rem;
    color: white;
  }
  
  .app-link:hover {
    color: black;
    text-shadow: none;
    font-weight: bold;
  }

