:root {
    --darkerBlue:#8adbdb;
    --lightBlue: #BCFEFF;
  }

.navbar-all {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Genos;
}

.navbar-menu {
    display: flex !important;
    justify-content: flex-end !important;
    align-items: center;
    height: 4rem;
    top: 0;
    left: 0;
    width: 90%;
    font-size: 30px;
    padding-right: 0 !important;
    padding-left: 15px !important;
}

:-webkit-any-link {
    cursor: pointer;
    text-decoration: none;
    padding: 0 1rem;
}

.navbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: Genos;
    font-size: 40px;
    color:var(--darkerBlue);
  }

.navbar-collapse {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: Genos;
    font-size: 40px;
  }

  .me-auto {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-family: Genos;
    font-size: 40px;
    width: 100%;
    background-color: black;
    padding-right:0;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border: 1px solid #364d4d;
  }

  .filler {
    display:flex;
    width: 120%
  }

  .fixed-top {
    top: 2% !important; ;
  }

  #navbarScrollingDropdown {
    color:var(--darkerBlue);
  }

  .dropdown-menu {
    color:var(--darkerBlue) !important;
    background-color: black !important;
  }
  .dropdown-item {
    color:var(--darkerBlue) !important;
  }
 .dropdown-item.active {
    background-color: black !important;
    color: var(--lightBlue) !important;
    font-weight: bold;
  }
  .dropdown-item:hover {
    background-color: black !important;
    color: white !important;
    font-weight: bold;
  }
  .nav-link.active {
    font-weight: bold;
    color: var(--lightBlue) !important;
  }
  .nav-link:hover {
    color: white!important;
    font-weight: bold;
  }
  .navbar-brand {
    color: var(--lightBlue) !important;
  }
  .navbar-brand:hover{
    color: #000000 !important;
    text-shadow:none !important;
    font-weight: bold;
  }
 
    @media (max-width: 1750px) {
      .navbar-brand {
        font-size: 40px !important;
        text-shadow: 2px 2px 4px #000000;
      }
      }

    @media (max-width: 1450px) {
      .navbar-brand {
        font-size: 35px !important;
        text-shadow: 2px 2px 4px #000000;
      }
      }

    @media (max-width: 1215px) {
      .navbar-brand {
        font-size: 30px !important;
        text-shadow: 2px 2px 4px #000000;
      }
      }

    @media (max-width: 1025px) {
      .me-auto {
        border: none !important;
      }
      .navbar-menu {
        background-color: var(--darkerBlue) !important; 
      }
      .navbar-brand {
        color: black !important;
        text-shadow: none !important;
      }
      .me-auto {
        background-color: var(--darkerBlue) !important; 
        color: black !important;
      }
      .nav-link {
        color: black !important;
        font-size: 30px !important;
      }
      .dropdown-item {
        background-color: var(--darkerBlue) !important;
        color: black !important;
      }
      .dropdown-item:hover,
      .dropdown-item.active {
        background-color: var(--darkerBlue) !important;
        color: black !important;
        font-weight: bold !important; 
      }
      .dropdown-item.active {
        color: black !important;
        font-weight: bold !important; 
      }
      .dropdown-menu {
        background-color: var(--darkerBlue) !important;
      }
      .nav-link:hover {
        color: black !important;
        font-weight: bold;
      }
      .nav-link.active {
        color: black !important;
        font-weight: bold;
      }
}

    @media (max-width: 530px) {
      .nav-link {
        font-size: 25px !important;
      }
      }

    @media (max-width: 330px) {
      .nav-link {
        font-size: 15px !important;
      }
      .dropdown-item {
        font-size: 12px !important;
      }
  }
